import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'hooks/support/useTranslation';
import { formatPrice } from 'utility/common';
import InsuranceIcon from '../icons/insurance-icon';
import Card, { ICard } from './card';
import { Route } from 'utility/routes';
import { OfferExpireChip } from '../chip/offer-expire-chip';
import { usePolicies } from 'hooks/api/usePolicies';
import { Status } from 'utility/node/tia-rest/model/response/policy.response.model';

const OpenQuoteCard = (): JSX.Element => {
  const { activeQuotes, firstQuoteToExpire } = usePolicies();

  const { t } = useTranslation('quote');

  const containsSuspendedQuotes = activeQuotes?.some(
    (q) => q.status === Status.SUSPENDED_QUOTE
  );

  if (!activeQuotes || activeQuotes.length === 0) {
    return null;
  }
  if (!firstQuoteToExpire) {
    return null;
  }

  const totalPrice = activeQuotes.reduce(
    (sumPrice, quote) => sumPrice + quote.pricePaid,
    0
  );

  const card: ICard = {
    href: Route.BASKET,
    header: {
      title: t('openQuote'),
      subHeader: t('subHeader', false, {
        count: activeQuotes.length,
        pricePerYear: formatPrice(totalPrice),
      }),
      subHeader2:
        !!totalPrice &&
        !containsSuspendedQuotes &&
        t('subHeader2', false, {
          pricePerYear: formatPrice(totalPrice),
        }),
      badge: {
        element: (
          <Typography color="warning.light" sx={{ mt: -2, ml: -2 }}>
            <InsuranceIcon height={110} width={110} />
          </Typography>
        ),
        status: (
          <OfferExpireChip
            quoteExpirationInDays={firstQuoteToExpire?.quoteExpirationInDays}
          />
        ),
      },
    },
    elementKey: 'QuoteCard',
    cardType: 'offer',
  };

  return (
    <Grid item xs={12} sm={6} lg={4} key={`offerCard`}>
      <Card {...card} />
    </Grid>
  );
};

export default OpenQuoteCard;
