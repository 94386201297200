import useEmblaCarousel from 'embla-carousel-react';
import { Children, PropsWithChildren, useEffect } from 'react';
import CardSkeleton from '../card/card-skeleton';

interface SliderOverviewProps {
  loading: boolean;
}

const SlideOverview = ({
  loading,
  children,
}: PropsWithChildren<SliderOverviewProps>) => {
  const [viewportRef, emblaApi] = useEmblaCarousel({
    containScroll: 'keepSnaps',
    dragFree: true,
  });

  useEffect(() => {
    if (emblaApi) emblaApi.reInit();
  }, [children, emblaApi]);

  const arrayChildren = Children.toArray(children);

  return (
    <div className="embla">
      <div className="embla__viewport" ref={viewportRef}>
        <div className="embla__container">
          {loading ? (
            <>
              <div className="embla__slide" key="skeleton-1">
                {<CardSkeleton sx={{ minWidth: 293 }} />}
              </div>
              <div className="embla__slide" key="skeleton-2">
                {<CardSkeleton sx={{ minWidth: 293 }} />}
              </div>
              <div className="embla__slide" key="skeleton-3">
                {<CardSkeleton sx={{ minWidth: 293 }} />}
              </div>
            </>
          ) : (
            arrayChildren.map((card, index) => (
              <div className="embla__slide" key={index}>
                {card}
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default SlideOverview;
