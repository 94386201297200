import { Typography } from '@mui/material';

export const GreetingTextOfAppBar = ({ text }) => {
  return (
    <Typography
      variant="h3"
      color="primary.contrastText"
      component="div"
      sx={{
        pt: 1,
        pb: 3,
        mb: { xs: 2, md: 1 },
        mx: 3,
        textAlign: { md: 'center', xs: 'left' },
        minHeight: 78,
      }}
    >
      {text}
    </Typography>
  );
};
