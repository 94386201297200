import { PropsWithChildren, ReactNode } from 'react';
interface SwrWrapperProps {
  data: any | any[];
  error: boolean;
  errorComponent?: ReactNode;
  emptyComponent?: ReactNode;
  loadingComponent?: ReactNode | ReactNode[];
}

const SwrWrapper = ({
  data,
  error,
  errorComponent,
  emptyComponent,
  loadingComponent,
  children,
}: PropsWithChildren<SwrWrapperProps>) => {
  const isLoading = !data && !error;
  const hasError = !data && error;
  const isArray = Array.isArray(data);
  const isEmpty = !data || (isArray && data.length <= 0);

  if (errorComponent && hasError) {
    return <>{errorComponent}</>;
  }

  if (loadingComponent && isLoading) {
    return <>{loadingComponent}</>;
  }

  if (emptyComponent && !isLoading && isEmpty) {
    return <>{emptyComponent}</>;
  }

  return <>{children}</>;
};

export default SwrWrapper;
