import { AccessTimeOutlined } from '@mui/icons-material';
import { Chip, SxProps } from '@mui/material';
import { useTranslation } from 'hooks/support/useTranslation';
import LoadingComponent from '../loading-component/loading-component';
import { format } from 'date-fns';
import daLocale from 'date-fns/locale/da';

export const OfferExpireChip = ({
  quoteExpirationInDays,
  style = 'warning',
}: {
  quoteExpirationInDays: number;
  style?: 'default' | 'warning';
}) => {
  const { t } = useTranslation('quote');

  if (!quoteExpirationInDays) {
    return <LoadingComponent />;
  }

  const today = new Date();
  const daysLeftTextObj = {
    daysLeft: quoteExpirationInDays,
    weekday: format(
      new Date(today.setDate(today.getDate() + quoteExpirationInDays)),
      'EEEE',
      { locale: daLocale }
    ),
  };
  let daysLeftText = '';
  if (quoteExpirationInDays <= 2)
    daysLeftText = t(
      'daysLeft' + quoteExpirationInDays,
      false,
      daysLeftTextObj
    );
  else if (quoteExpirationInDays <= 6)
    daysLeftText = t('daysLeftWeek', false, daysLeftTextObj);
  else daysLeftText = t('daysLeft', false, daysLeftTextObj);

  const sx: SxProps =
    style === 'warning'
      ? {
          backgroundColor: 'warning.light',
          color: `warning.contrastText`,
          '.MuiChip-icon': {
            color: `warning.contrastText`,
          },
        }
      : {
          backgroundColor: 'primary.ultraLight',
        };

  return (
    <Chip
      color="default"
      sx={{ ...sx }}
      size="small"
      label={daysLeftText}
      variant="filled"
      key="1"
      icon={<AccessTimeOutlined />}
    />
  );
};
