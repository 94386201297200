import { Box, Stack, Typography } from '@mui/material';
import { useTranslation } from 'hooks/support/useTranslation';
import Image from 'next/legacy/image';
import { formatPrice } from 'utility/common';
import { PolicySlim } from 'utility/node/policy/policy.model';
import { Status } from 'utility/node/tia-rest/model/response/policy.response.model';
import { getPolicyDetailsRoute } from 'utility/routes';
import { SentryGroup, addMessageWithGroup } from 'utility/sentry';
import PolicyChip from '../chip/policy-chip';
import Card, { ICard } from './card';

const PolicyCard = ({
  policy,
  status,
  href,
}: {
  policy: PolicySlim;
  status?: JSX.Element | string;
  href?: string;
}) => {
  const { t } = useTranslation('policy');

  const line = policy.policyLines[0];
  if (!line) {
    const message = 'Policies without associated lines are not supported';
    addMessageWithGroup({
      message: message,
      group: SentryGroup.tiaPolicy,
      printToConsole: true,
      consoleMessage: message,
      tag: 'policyCard',
    });
    return undefined;
  }

  const title = policy.metadata?.name || line.productLineId;
  const icon =
    policy.metadata?.icon?.imageUrl || '/img/product/fallback-icon.png';
  const statusElement = status || (
    <PolicyChip
      policyStatus={policy.status}
      cancelationDate={policy.cancelationDate}
    />
  );
  const policyCard: ICard = {
    elementKey: `policyCard-${policy.policyNo}`,
    header: {
      badge: {
        status: statusElement,
      },
    },
    content: {
      content: (
        <Stack>
          <Typography className="u" variant="subtitle1">
            {title}
          </Typography>
          <Typography variant="body2">{line.shortDescription}</Typography>
        </Stack>
      ),
    },

    avatar: (
      <Image width={60} height={60} alt={`icon ${icon}`} src={`${icon}`} />
    ),
    actions: policy.status !== Status.EXPIRED && (
      <Box sx={{ display: 'flex', width: '100%' }}>
        <Typography
          component="div"
          sx={{ ml: 'auto', display: 'inline-block' }}
          variant="caption"
        >
          {t('price', false, { price: formatPrice(policy.pricePaid) })}
        </Typography>
      </Box>
    ),
    href: href ? href : getPolicyDetailsRoute(policy.policyNo),
    cardType: 'policy',
    logObject: { policyNo: policy.policyNo },
  };

  return <Card sx={{ minWidth: { xs: 260, md: 'auto' } }} {...policyCard} />;
};

export default PolicyCard;
