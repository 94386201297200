import { Chip } from '@mui/material';
import { useTranslation } from 'hooks/support/useTranslation';
import { getShortDate } from 'utility/date';
import { Status } from 'utility/node/tia-rest/model/response/policy.response.model';

const PolicyChip = ({
  policyStatus,
  cancelationDate,
}: {
  policyStatus: Status;
  cancelationDate: Date;
}) => {
  const { t } = useTranslation('policy');

  const getStatusColor = () => {
    switch (policyStatus) {
      case Status.ACTIVE:
        return {
          text: 'success.contrastText',
          background: 'success.light',
        };
      case Status.AWAITING_INPUT:
      case Status.LATER_IN_FORCE:
        return {
          text: 'info.contrastText',
          background: 'info.light',
        };
      case Status.EXPIRED:
        return {
          text: 'text.primary.contrastText',
          background: 'secondary.dark',
        };
      case Status.QUOTE:
        return {
          text: 'warning.contrastText',
          background: 'warning.light',
        };
      default:
        return {
          text: 'text.primary.contrastText',
          background: 'secondary.dark',
        };
    }
  };

  const statusColor = getStatusColor();

  return (
    <Chip
      className="u"
      sx={{
        maxWidth: 180,
        backgroundColor: statusColor.background,
        color: statusColor.text,
      }}
      size="small"
      label={t(`policyStatus.${policyStatus}`, false, {
        cancelDate: getShortDate(cancelationDate),
      })}
      variant="filled"
      key="1"
    />
  );
};

export default PolicyChip;
