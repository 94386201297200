import { Button, Stack, Typography } from '@mui/material';
import Image from 'next/legacy/image';
import RefreshOutlined from '@mui/icons-material/RefreshOutlined';
import { useRouter } from 'next/router';
import React, { useContext } from 'react';
import { useTranslation } from 'hooks/support/useTranslation';
import { BrandContext } from 'pages/_app';
import { KeyedMutator } from 'swr/_internal';

const ErrorComponent = ({
  swrMutate,
  children,
  error,
  showUpdateAction = true,
}: {
  children?: React.ReactNode;
  swrMutate?: KeyedMutator<any>;
  error?: boolean;
  showUpdateAction?: boolean;
}) => {
  const router = useRouter();
  const { t } = useTranslation('common');
  const brand = useContext(BrandContext);
  let onClick: () => void = () => router.reload();

  if (!swrMutate) {
    onClick = () => swrMutate;
  }

  if (error !== false) {
    return (
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={1}
        sx={{ my: 3 }}
      >
        <Image
          src={brand.details.media.errorImage.imageUrl}
          width={150}
          height={150}
          alt=""
        />
        <Typography variant="subtitle1" color="warning.main">
          {t('error.subtitle')}
        </Typography>
        <Typography
          textAlign="center"
          sx={{ whiteSpace: 'pre-wrap' }}
          variant="body2"
        >
          {t('error.body')}
        </Typography>
        {showUpdateAction && (
          <Button
            onClick={onClick}
            startIcon={<RefreshOutlined />}
            variant="contained"
          >
            {t('error.actionTryAgain')}
          </Button>
        )}
      </Stack>
    );
  }

  return <>{children}</>;
};

export default ErrorComponent;
