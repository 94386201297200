import { Skeleton, Stack } from '@mui/material';
import { SxProps } from '@mui/material';
import { Theme } from '@mui/material';

const CardSkeleton = ({
  sx,
  media = true,
  avatar = true,
  actions = true,
}: {
  sx?: SxProps<Theme>;
  media?: boolean;
  avatar?: boolean;
  actions?: boolean;
}) => (
  <Stack spacing={1} sx={{ ...sx }}>
    {media && <Skeleton variant="rectangular" width="100%" height={116} />}
    <Stack direction="row" spacing={1}>
      {avatar && (
        <Skeleton variant="circular" width={40} height={40} sx={{ my: 3 }} />
      )}
      <Skeleton variant="text" sx={{ minHeight: '52px' }} width="100%" />
    </Stack>
    {actions && <Skeleton variant="text" width="100%" height={24} />}
  </Stack>
);

export default CardSkeleton;
