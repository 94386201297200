import { Box, Stack, SxProps, Theme, Typography } from '@mui/material';
import { ReactNode } from 'react';

const EmptyComponent = ({
  body,
  subtitle,
  actions,
  sx,
}: {
  body: string;
  subtitle?: string;
  actions?: ReactNode | ReactNode[];
  sx?: SxProps<Theme>;
}) => (
  <Stack spacing={4} sx={{ ...sx }}>
    <Typography variant="body2" color="warning.main">
      {body}
    </Typography>
    {subtitle && <Typography variant="subtitle2">{subtitle}</Typography>}
    <Box>{actions}</Box>
  </Stack>
);

export default EmptyComponent;
