import { Box, Button, IconButton, Link, SxProps, Theme } from '@mui/material';
import { BrandContext } from 'pages/_app';
import { ReactNode, useContext } from 'react';
import { Brands } from 'utility/brands';
import { getBrandDetails } from 'utility/node/sanity/sanity';

export type mainActions = {
  key: string | number;
  icon: ReactNode;
  text: string;
  path?: string;
  onClick?: () => void;
  backgroundColor?: string;
};

export const HeaderMainActions = ({
  mainActions,
  topStyling,
  actionStyling,
}: {
  mainActions: mainActions[];
  topStyling?: SxProps<Theme>;
  actionStyling?: SxProps<Theme>;
}) => {
  const brand = useContext(BrandContext);
  const isForsia = brand.details.brandAbbreviation === Brands.FORSIA;
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-evenly',
        maxWidth: { sm: 435, xs: 300 },
        mx: 'auto',
        ...topStyling,
      }}
    >
      {mainActions.map(
        ({ icon, key, text, path, onClick, backgroundColor }) => (
          <Box className="u" key={key} sx={{ ...actionStyling }}>
            <Link underline="none" href={path || '/'}>
              <>
                <IconButton
                  onClick={onClick}
                  sx={{
                    width: { xs: 60, md: 80 },
                    height: { xs: 60, md: 80 },
                    bgcolor: `${
                      backgroundColor
                        ? backgroundColor
                        : isForsia
                        ? 'primary.dark'
                        : 'primary.main'
                    }`,
                    color: `${
                      isForsia ? 'primary.main' : 'primary.contrastText'
                    }`,
                    mx: 'auto',
                    display: 'flex',
                    '&:hover': {
                      backgroundColor: 'primary.dark',
                    },
                  }}
                >
                  {icon}
                </IconButton>
                <Button
                  variant="text"
                  sx={{ display: 'block', color: 'primary.contrastText' }}
                >
                  {text}
                </Button>
              </>
            </Link>
          </Box>
        )
      )}
    </Box>
  );
};
