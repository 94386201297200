import {
  Grid,
  Button,
  Stack,
  Box,
  Typography,
  TypographyVariant,
} from '@mui/material';
import EmptyComponent from 'components/empty-component/empty-component';
import ErrorComponent from 'components/error-component/error-component';
import ContactIcon from 'components/icons/contact-icon';
import InsuranceIcon from 'components/icons/insurance-icon';
import SlideOverview from 'components/slide-overview/slide-overview';
import SwrWrapper from 'components/swr-wrapper/swr-wrapper';
import Link from 'components/link/link';
import CardSkeleton from './card-skeleton';
import { usePolicies } from 'hooks/api/usePolicies';
import { Status as PolicyStatus } from 'utility/node/tia-rest/model/response/policy.response.model';
import PolicyCard from './policy-card';
import { Route } from 'utility/routes';
import { useTranslation } from 'hooks/support/useTranslation';
import { formatPrice } from 'utility/common';

const PolicyCards = ({
  swipeableInMobileView,
  insuranceTitle,
  totalPriceSection = false,
  insuranceTitleVariant = 'h6',
}: {
  swipeableInMobileView: boolean;
  insuranceTitle: string;
  totalPriceSection: boolean;
  insuranceTitleVariant?: TypographyVariant;
}) => {
  const { t } = useTranslation('home');

  const { policies, policiesErrors, mutatePolicies, activePolicies } =
    usePolicies();

  const policyCards = policies
    ?.filter(
      (p) =>
        p.status == PolicyStatus.ACTIVE ||
        p.status == PolicyStatus.LATER_IN_FORCE ||
        p.status == PolicyStatus.AWAITING_INPUT
    )
    .map((policy) => (
      <PolicyCard policy={policy} key={`policy-${policy.policyNo}`} />
    ));

  const policesLoading = !policies && !policiesErrors;

  return (
    <SwrWrapper
      data={policyCards}
      error={policiesErrors}
      errorComponent={<ErrorComponent swrMutate={mutatePolicies} />}
      emptyComponent={
        <EmptyComponent
          body={t('policies.noPolicies.body')}
          subtitle={t('policies.noPolicies.subtitle')}
          actions={
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1}>
              <Link underline="none" key="1" href={Route.BUY_INSURANCES}>
                <Button
                  variant="contained"
                  startIcon={<InsuranceIcon width={17} height={17} />}
                >
                  {t('policies.noPolicies.actionOurInsurances')}
                </Button>
              </Link>

              <Link key="2" href={Route.CONTACT} underline="none">
                <Button
                  variant="outlined"
                  startIcon={<ContactIcon width={17} height={17} />}
                >
                  {t('policies.noPolicies.actionContactUs')}
                </Button>
              </Link>
            </Stack>
          }
        />
      }
    >
      <>
        <Typography
          variant={insuranceTitleVariant}
          sx={{ display: 'inline-block', py: 2 }}
        >
          {insuranceTitle}
        </Typography>
        {totalPriceSection && (
          <Box
            display="flex"
            justifyContent="space-between"
            gap={2}
            sx={{ py: 2 }}
          >
            <Typography variant="h6">Total</Typography>
            <Typography variant="h6" color="primary.light">
              {t('policies.totalPrice', false, {
                amt: formatPrice(
                  activePolicies?.reduce(
                    (sum, policy) => sum + policy.pricePaid,
                    0
                  )
                ),
              })}
            </Typography>
          </Box>
        )}

        {swipeableInMobileView && (
          <Box sx={{ display: { xs: 'block', md: 'none' } }}>
            <SlideOverview loading={policesLoading}>
              {policyCards}
            </SlideOverview>
          </Box>
        )}
        <Box
          sx={{
            display: {
              xs: `${swipeableInMobileView ? 'none' : 'block'}`,
              md: 'block',
            },
          }}
        >
          <Grid container spacing={2}>
            {!policesLoading ? (
              policyCards?.map((card, index) => (
                <Grid item xs={12} md={6} lg={4} xl={3} key={`policy-${index}`}>
                  {card}
                </Grid>
              ))
            ) : (
              <>
                <Grid item md={6} lg={4} xl={3} key="skeleton-1">
                  <CardSkeleton />
                </Grid>
                <Grid item md={6} lg={4} xl={3} key="skeleton-2">
                  <CardSkeleton />
                </Grid>
                <Grid item md={6} lg={4} xl={3} key="skeleton-3">
                  <CardSkeleton />
                </Grid>
              </>
            )}
          </Grid>
        </Box>
      </>
    </SwrWrapper>
  );
};

export default PolicyCards;
